<template>
  <div class="container is-fluid">
    <div class="columns grid_height">
      <div class="column company_image_bg">
        <div class="is-flex quote-text">
          <img class="quote-img" src="../../assets/images/quote.png" alt="" />
          <h1 class="gradient-text">
            Good company in a journey makes the way seem shorter
          </h1>
        </div>
        <div class="author">
          <p class="author-text">Izak Walton</p>
        </div>
      </div>

      <div class="column form-companies">
        <img class="company-logo" src="/images/rise_logo_red.png" alt="" />
        <h1 class="create_company_text">Sorry You Don’t Have Company</h1>
        <img class="img-mock" src="../../assets/images/building.png" />
        <p class="text-info">
          Please make sure your already invited to the company for more
          information check website
          <a href="https://getrise.id/">getrise.id</a>
        </p>
        <div class="text-footers">
          <p>
            PT Roketin Kreatif Teknologi. Copyright
            {{ new Date().getFullYear() }}. RISE {{ appVersion }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { appVersion } from '@/version'
export default {
  computed: {
    appVersion: () => appVersion,
  },
}
</script>

<style></style>
